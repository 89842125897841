import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ExaminerCommentBankService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/examiner-comment-bank-category';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    all(){
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }
    getByUser(userId){
        let url = `${this.#api}/${userId}/get/by/user`;
        return apiService.get(url)
    }
}
import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ExaminerCommentBankService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/examiner-comment-bank';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    exportToCsv(data){
        let url = `${this.#api}/export/csv?info=${data.name}&status=${data.status}&examiner_comment_bank_category_id=${data.examiner_comment_bank_category_id}&user_id=${data.user_id}`;
        window.open(url, "_blank")
    }
    exportToExcel(data){
        let url = `${this.#api}/export/excel?info=${data.name}&status=${data.status}&examiner_comment_bank_category_id=${data.examiner_comment_bank_category_id}&user_id=${data.user_id}`;
        window.open(url, "_blank")
    }
}
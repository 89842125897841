<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                    Comment banks
                                </h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard </router-link>\ Comment banks
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <b-dropdown class="ml-5" size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" 
                                        no-caret 
                                        right 
                                        no-flip
                                    >
                                        <template v-slot:button-content>
                                        <i class="ki ki-bold-more-hor"></i>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover min-w-md-250px">
                                        <b-dropdown-text tag="div" class="navi-item">
                                            <a @click="exportToExcel()" class="navi-link">
                                            <span class="navi-icon">
                                                <i class="fa fa-file-excel"></i>
                                            </span>
                                            <span class="navi-text">Export (excel)</span>
                                            </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div" class="navi-item">
                                            <a class="navi-link" @click="exportToCsv()">
                                            <span class="navi-icon">
                                                <i class="fa fa-file-csv"></i>
                                            </span>
                                            <span class="navi-text">Export (CSV)</span>
                                            </a>
                                        </b-dropdown-text>
                                        </div>
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field 
                                        label="Search by comment title"
                                        v-model="search.name" 
                                        outlined 
                                        dense
                                        v-on:keyup.enter="searchCommentBanks()"
                                        @input="search.name = $event !== null ? $event : ''" 
                                        clearable
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete
                                        v-model="selectedExaminer"
                                        :search-input.sync="examinerSearch"
                                        :items="examiners"
                                        item-text="display_text"
                                        item-value="id"
                                        label="Search by examiner"
                                        return-object
                                        hide-selected
                                        clearable
                                        outlined
                                        dense
                                        @change="handleExaminerChange"
                                    >
                                        <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                            <v-list-item-title>
                                                <strong>Search by name, email </strong>
                                            </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        v-model="search.examiner_comment_bank_category_id"
                                        :items="categories"
                                        item-text="name"
                                        item-value="id"
                                        :loading="categoryLoading"
                                        clearable
                                        @input="search.examiner_comment_bank_category_id = $event !== null ? $event : ''" 
                                        label="Comment bank category"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-select 
                                        :items="search_statuses" 
                                        v-model="search.status" 
                                        label="Status" 
                                        item-text="name" 
                                        item-value="value" 
                                        v-on:keyup.enter="searchCommentBanks()"
                                        @input="search.status = $event !== null ? $event : ''" 
                                        outlined 
                                        clearable
                                        dense
                                    ></v-select>
                                </v-col>
                                    
                                <v-col cols="12" sm="12" md="12" class="text-right">
                                    <v-btn 
                                        @click="searchCommentBanks()" 
                                        :loading="loading" 
                                        class="btn btn-primary w-35 float-right"
                                        dark
                                    >
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>

                        </div>

                        <div class="table-responsive">
                            <v-skeleton-loader type="table-thead" v-if="loading">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table">
                                <thead>
                                    <tr class="text-left">
                                        <th class="px-3">Name</th>
                                        <th class="px-3">Category</th>
                                        <th class="px-3" style="max-width: 400px; white-space: pre-wrap;">Comment</th>
                                        <th class="px-3">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr v-for="(item, index) in commentBanks" :key="index">
                                            <td class="px-2">
                                                <strong>
                                                    {{ item.name }}
                                                </strong>
                                                <div v-if="item.examiner_name">
                                                    <strong>Examiner: </strong>
                                                    {{ item.examiner_name }}
                                                </div>
                                            </td>
                                            <td class="px-3">
                                                {{ item.category_name }}
                                            </td>
                                            <td class="px-3" style="max-width: 400px; white-space: pre-wrap;">
                                                <div v-html="item.comment"></div>
                                            </td>
                                            <td class="px-2">
                                                <span class="badge"
                                                    v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                                                    >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                                            </td>
                                        </tr>
                                        <tr v-if="commentBanks.length == 0">
                                            <td colspan="4" class="text-center">
                                                No items found
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <b-pagination v-if="commentBanks.length > 0" class="pull-right mt-7" @input="getCommentBanks"
                                v-model="page" :total-rows="total" :per-page="perPage" first-number last-number
                                :disabled="loading"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import ExaminerService from "@/services/user/examiner/ExaminerService";
import ExaminerCommentBankService from "@/services/user/examiner/examiner-comment-bank/ExaminerCommentBankService";
import ExaminerCommentBankCategoryService from "@/services/user/examiner/examiner-comment-bank-category/ExaminerCommentBankCategoryService";

const examinerCommentBank = new ExaminerCommentBankService();
const examiner = new ExaminerService();
const examinerCommentBankCategory = new ExaminerCommentBankCategoryService();

export default {
    data() {
        return {
            total: null,
            perPage: null,
            page: null,
            loading: true,
            search: {
                name: '',
                examiner_comment_bank_category_id: '',
                status: '',
                user_id: ''
            },
            currentUser: '',
            commentBanks: [],
            categories: [],
            search_statuses:[
                {
                    name: 'Active',
                    value: '1'
                },
                {
                    name: 'Inactive',
                    value: '0'
                },
            ],
            selectedExaminer: '',
            examinerSearch: null,
            examiners: [],
            categoryLoading: false
        }
    },
    methods: {
        searchCommentBanks() {
            this.page = 1;

            if(this.selectedExaminer){
                this.search.user_id = this.selectedExaminer.id;
            }else{
                this.search.user_id = '';
            }
            this.getCommentBanks();
        },
        getCurrentUser() {
            this.currentUser = this.$store.getters.currentUser;
        },
        getCommentBanks() {
            this.loading = true;

            examinerCommentBank
                .paginate(this.search, this.page)
                .then(response => {
                    this.commentBanks = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                })
                .catch((err) => {
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getCommentBankCategoriesByUser(){
            this.categoryLoading = true;

            examinerCommentBankCategory
                .getByUser(this.selectedExaminer.id)
                .then(response => {
                    this.categories = response.data.examinerCommentBankCategories;
                    this.categoryLoading = false;
                })
                .catch((err) => {
                    this.categoryLoading = false;
                })
                .finally(() => {
                });
        },
        exportToExcel(){
            examinerCommentBank
            .exportToExcel(this.search);
        },
        exportToCsv(){
            examinerCommentBank
            .exportToCsv(this.search);
        },
        handleExaminerChange(){
            if(this.selectedExaminer){
                this.categories = [];
                this.search.examiner_comment_bank_category_id = '';
                this.getCommentBankCategoriesByUser();
                this.categoryLoading = false;
            }else{
                this.categories = [];
                this.search.examiner_comment_bank_category_id = '';
                this.categoryLoading = false;
            }
        }
    },
    watch:{
        examinerSearch(val) {
            examiner
            .searchByScore(val)
            .then((response) => {
                response.data.data.map((user) => {
                let data = user;
                data.display_text =
                    user.first_name +' '+ user.last_name + " | " + user.email
                this.examiners.push(data);
                });
            })
            .catch((err) => {

            })
            .finally(() => {});
        }
    },
    mounted() {
        this.getCommentBanks();
    }
}
</script>
